<template>
  <div class="apply">
    <div class="card">
      <div class="container">
        <img :src="info.images" />
        <div class="info">
          <h1>
            {{ info.bookname }}
            <br />
          </h1>
          <p class="validity">规格：{{ info.validity == 0 ? "永久" : info.validity+'天' }}</p>
          <p class="gold-num">￥{{ info.price }}</p>
        </div>
      </div>
    </div>

    <div class="main">
      
      <div class="box">
        <div class="row">
          <span>课程金额</span>
          <em>￥{{ info.price }}</em>
        </div>
        <div class="row">
          <span></span>
          <span>×1</span>
        </div>
        <div class="row">
          <b>应付金额</b>
          <em class="gold-num">{{ info.price }}</em>
        </div>
      </div>

      <div class="btn-bottom">
        <button class="btn-black" @click="pay">立即支付</button>
      </div>
    </div>
  </div>
</template>

<script>
import { lessonConfirm, createOrder } from "@api/public";
export default {
  name: "apply",
  data() {
    return {
      id: "",
      info: {}
    };
  },
  created() {
    if(this.$route.params.id) {
      this.id = this.$route.params.id
    }
    this.getList();
  },
  methods: {
    // 获取列表数据
    getList() {
      let that = this;
      let params = {
        id: that.id
      }

      lessonConfirm(params).then(res => {
        that.$set(that, 'info', res.data.info);
      }).catch(err => {
        let status = err.data.status;

        that.$set(that, 'info', err.data.data.info);
        that.$dialog.error(err.msg)
        if(status == 202) {
          setTimeout(() => {
            that.$router.push({
              path: `/lesson/onlineDetail/${that.info.id}`,
              query: {path: '/lesson/online'}
            })
          }, 2000);
        } else  if(status == 301) {
          setTimeout(() => {
            that.$router.push({
              path: '/user/MyLesson',
              query: {path: '/user'}
            })
          }, 2000);
        }
      })
    },

    // 支付
    pay() {
      let that = this;
      
      let params = {
        id: that.id
      }
      createOrder(params).then(res => {
        that.$router.push({
          path: `/lesson/pay`,
          query: {order_id: res.data.order_id}
        })
      }).catch(err => {
        that.$dialog.error(err.msg);

      })
    }
  }
};
</script>

<style lang="scss" scoped>
.apply {
  padding-bottom: 1rem;
  .card {
    background: white;
    .container {
      padding: 0.3rem;
      display: flex;
      justify-content: space-between;
      height: 2.6rem;
      img {
        width: 50%;
        height: 100%;
        background: black;
        border-radius: 0.2rem 0 0 0.2rem;
      }
      .info {
        width: 50%;
        padding: 0.1rem 0.2rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        box-shadow: 0 0.02rem 0.06rem #dddddd;
        border-radius: 0 0.2rem 0.2rem 0;
        h1 {
          font-size: 0.28rem;
          font-weight: 500;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }

  .main {
    .box {
      background: white;
      padding: .2rem 0.3rem;
      margin-top: .2rem;
      font-size: .28rem;
      p {
        margin: .1rem 0;
      }
      .row {
        display: flex;
        justify-content: space-between;
        padding: .1rem 0;
        em {
          font-family: PingFang SC;
        }
      }
    }
  }  
}
</style>
